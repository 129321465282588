<template>
  <div class="customerManagement-view">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的客户" name="first">
        <exclusiveClients
          v-if="activeName == 'first'"
        />
      </el-tab-pane>
      <el-tab-pane label="成单客户" name="second">
        <singleCustomer
          v-if="activeName == 'second'"
        />
      </el-tab-pane>
      <el-tab-pane label="公海客户" name="third">
        <sharedClient
          v-if="activeName == 'third'"
        />
      </el-tab-pane>
      <template v-if="userInfo.roleId || jurisdiction || userInfo.main">
        <el-tab-pane label="售后服务客户" name="fourth">
          <afterSales
            v-if="activeName == 'fourth'"
          />
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import exclusiveClients from "./components/customerManagement/exclusiveClients";
import singleCustomer from "./components/customerManagement/singleCustomer";
import sharedClient from "./components/customerManagement/sharedClient";
import afterSales from "./components/customerManagement/afterSales";
import { mapState,mapMutations } from 'vuex';
export default {
  name: "customer-management",
  components: {
    exclusiveClients,
    singleCustomer,
    sharedClient,
    afterSales
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      activeName: "first",
      jurisdiction:""
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      console.log(val)
      this.jurisdiction = val.includes('011')
    }
  },
  // watch: {
  //   activeName(val, lod) {
  //     switch (val) {
  //       case "first":
  //         this.customerState = 1;
  //         break;
  //       case "second":
  //         this.customerState = 2;
  //         break;
  //       case "third":
  //         this.customerState = 3;
  //         break;
  //       default:
  //         return;
  //     }
  //   },
  // },
  
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
    
  },
};
</script>

<style lang="scss" scoped>
</style>